<template>
  <div class="big container">
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <!-- 售后弹框 -->
    <el-dialog
      title="售后"
      :visible.sync="afterBuy"
      width="510px"
      style="text-align: left;"
      @closed="clearData('ruleForm')"
      :close-on-click-modal="false"
    >
      <div>
        <div class="info_main">
          <div>退款商品:</div>
          <div class="list_box">
            <div
              class="list_item"
              v-for="(item, index) in afterList"
              :key="index"
            >
              <div class="list_top">
                <img
                  :src="item.picture_str"
                  alt
                  style="width: 76px; height: 76px; margin-right: 10px"
                />
                <div
                  style="display: flex;flex-direction: column;justify-content: space-between;"
                >
                  <div>{{ item.goods_name }}</div>
                  <div>数量{{ item.can_num }}</div>
                </div>
              </div>
              <!-- <div class="list_bottom">
                <div>
                  <el-input-number
                    @change="handleChange"
                    v-model="item.num"
                    :min="1"
                    :max="item.can_num"
                  ></el-input-number>
                </div>
              </div> -->
            </div>
          </div>
          <div class="list_form">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <el-form-item label="退款类型" prop="service_type">
                <el-radio-group v-model="ruleForm.service_type">
                  <el-radio :label="1">仅退款</el-radio>
                  <el-radio :label="2" v-if="detail.status != 2"
                    >退货退款</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="退款原因" prop="refund_desc">
                <el-select
                  v-model="ruleForm.refund_desc"
                  placeholder="请选择退款原因"
                >
                  <el-option
                    :label="e.msg"
                    :value="e.id"
                    v-for="(e, k) in afterReason"
                    :key="k"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="is-required"
                label="退款金额"
                prop="refund_price"
              >
                <el-input-number
                  style="width:300px"
                  :step="0.01"
                  :min="0"
                  :max="Number(ruleForm.tatoleprice)"
                  step-strictly
                  :controls="false"
                  v-model="ruleForm.refund_price"
                  :placeholder="`可退￥${ruleForm.tatoleprice}`"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                label="退运费金额"
                v-if="afterList.length ? afterList[0].is_freight_price : false"
              >
                <el-input-number
                  style="width:300px"
                  :step="0.01"
                  :min="0"
                  :max="Number(ruleForm.can_refund_freight_price)"
                  step-strictly
                  :controls="false"
                  v-model="ruleForm.refund_freight_price"
                  :placeholder="`可退￥${ruleForm.can_refund_freight_price}`"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="退款说明" prop="refund_remark">
                <el-input
                  type="textarea"
                  style="width:300px"
                  maxlength="200"
                  show-word-limit
                  v-model="ruleForm.refund_remark"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-upload
                  list-type="picture-card"
                  :http-request="
                    (parms) => {
                      uploadImg(parms);
                    }
                  "
                  :file-list="fileList"
                  action="description"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :limit="10"
                  accept=".jpg,.png,jpeg"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div style="text-align: right; padding-right: 20px">
          <el-button
            type="primary"
            @click="afterBuy = false"
            style="width: 92px"
            >取消
          </el-button>
          <el-button
            type="primary"
            @click="afterBuyCerten('ruleForm')"
            style="width: 92px"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 退货退款的确认信息弹窗 -->
    <el-dialog
      title="退款确认"
      :visible.sync="certenAfterAdress"
      width="510px"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        label-width="100px"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="联系人" prop="service_name">
          <el-input
            v-model="address_info.user_name"
            style="width: 370px"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="service_phone">
          <el-input
            v-model="address_info.phone"
            style="width: 370px"
          ></el-input>
        </el-form-item>

        <el-form-item label="省市区" prop="regions">
          <el-cascader
            v-model="address_info.regions"
            :options="regions"
            :props="goodsTypeSet"
            placeholder="选择地区"
            style="width: 370px"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="service_address">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 10 }"
            placeholder="详细地址"
            v-model="address_info.address"
            style="width: 370px"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="certenAfterAdress = false">取 消</el-button>
        <el-button @click="changeInfo()">修改信息</el-button>
        <el-button type="primary" @click="certenData()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 退款确认 -->
    <el-dialog
      title="退款确认"
      :visible.sync="certenAfterShow"
      width="510px"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <div>
        <div style="color:red">
          确认提交后将生成已完成商家处理的售后单，退款请联系用户退货；仅退款则会将退款打到用户账号，请再次确认售后信息。
        </div>
        <div style="display:flex;align-items: baseline;margin-top:20px;">
          <div style="margin-right: 3px;">
            退款类型：
          </div>
          <div style="margin-right: 50px;">
            {{ afterBuyCertendata.service_type | filterStatus }}
          </div>

          <div style="margin-right: 3px;">
            退款金额：
          </div>
          <div>
            ￥{{
              (
                Number(afterBuyCertendata.refund_price) +
                (Number(afterBuyCertendata.refund_freight_price)
                  ? Number(afterBuyCertendata.refund_freight_price)
                  : 0)
              ).toFixed(2)
            }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="certenAfterShow = false">取 消</el-button>
        <el-button @click="changeInfo()">修改信息</el-button>
        <el-button type="primary" @click="certenData()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 验证码弹框 -->
    <el-dialog
      title="验证码验证"
      :visible.sync="verificationShow"
      width="510px"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <div>
        <div>
          为保障数据安全，订单退款需填写歧黄助手接收到的验证码，验证成功后方可查看。
        </div>
        <div style="display:flex;align-items: center;margin-top:50px;">
          <div>验证码：</div>
          <div>
            <el-input v-model="code" placeholder="验证码"></el-input>
          </div>
          <div>
            <el-button
              @click="sendCode"
              type="primary"
              :disabled="canClick"
              style="width: 120px;margin-left: 40px;"
              size="small"
              >{{ btnText }}</el-button
            >
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="verificationShow = false">取 消</el-button>
        <el-button type="primary" :disabled="!code" @click="certenBtn()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 售后发货警告弹框 -->
    <el-dialog
      title="发货"
      :visible.sync="beforeDialog"
      width="510px"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <div>
        <div class="dialogbox">有商品处于售后，是否确认发货？</div>
        <div style="text-align: right; padding-right: 20px">
          <el-button
            type="primary"
            @click="beforeDialog = false"
            style="width: 92px"
            >取消
          </el-button>
          <el-button type="primary" @click="sendGoods()" style="width: 92px"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 发货弹窗 -->
    <el-dialog
      title="发货"
      :visible.sync="dialog"
      style="text-align: left;"
      width="800px"
      :close-on-click-modal="false"
    >
      <div>
        <div class="dialogbox">
          <div style="display:flex">
            <div style="word-break:keep-all;white-space:nowrap;">
              发货商品：
            </div>
            <div style="flex: 1;">
              <div style="margin-bottom:5px;">请确认您要发货的商品</div>
              <el-checkbox-group
                v-model="choose_item_list"
                style="display: inline-block;margin-bottom: 5px;"
              >
                <div style="display:flex;flex-direction: column;">
                  <el-checkbox
                    :label="item.item_id"
                    v-for="(item, index) in detail.item_list"
                    :key="index"
                    :disabled="item.delivery_status == 1"
                    style="margin-bottom:4px;"
                  >
                    <div
                      style="max-width: 600px;word-break:keep-all; white-space:nowrap; overflow: hidden; text-overflow:ellipsis;"
                    >
                      <span>{{ item.goods_name }}</span
                      ><span v-if="item.sku_name">[{{ item.sku_name }}]</span>
                      <span
                        v-if="item.service_show == 1"
                        style="color: red;margin-left: 5px;"
                        >售后中</span
                      >
                      <span
                        v-if="item.service_show == 2"
                        style="color: blue;margin-left: 5px;"
                        >已售后</span
                      >
                    </div>
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
          <div>
            <span>快递公司</span>
            <!-- <el-select
              v-model="co_id"
              placeholder="请选择"
              style="width: 370px; margin-left: 10px"
              @change="selectCompany"
            > -->
            <el-select
              v-model="co_id"
              placeholder="请选择"
              style="width: 370px; margin-left: 10px"
            >
              <el-option
                v-for="item in company_list"
                :key="item.value"
                :label="item.company_name"
                :value="item.co_id"
              ></el-option>
            </el-select>
          </div>
          <div>
            <span>快递单号</span>
            <el-input
              v-model="express_no"
              placeholder="请输入"
              style="width: 370px; margin-top: 20px; margin-left: 10px"
            ></el-input>
          </div>
        </div>
        <div style="text-align: right; padding-right: 20px">
          <el-button type="primary" @click="dialog = false" style="width: 92px"
            >取消
          </el-button>
          <el-button type="primary" @click="sendDialog" style="width: 92px"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!--新增物流弹窗 -->
    <el-dialog
      title="新增单号"
      :visible.sync="add_dialog"
      width="510px"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <div>
        <div class="dialogbox">
          <div>
            <span>快递公司</span>
            <el-select
              v-model="add_co_id"
              placeholder="请选择"
              style="width: 370px; margin-left: 10px"
              @change="selectCompany"
            >
              <el-option
                v-for="item in company_list"
                :key="item.value"
                :label="item.company_name"
                :value="item.co_id"
              ></el-option>
            </el-select>
          </div>
          <div>
            <span>快递单号</span>
            <el-input
              v-model="add_express_no"
              placeholder="请输入"
              style="width: 370px; margin-top: 20px; margin-left: 10px"
            ></el-input>
          </div>
        </div>
        <div style="text-align: right; padding-right: 20px">
          <el-button type="primary" @click="addGoods" style="width: 92px"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 重置物流弹窗 -->
    <el-dialog
      title="更新单号"
      :visible.sync="reset_dialog"
      width="510px"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <div>
        <div class="dialogbox">
          <div>
            <span>快递公司</span>
            <!-- <el-select
              v-model="co_id"
              placeholder="请选择"
              style="width: 370px; margin-left: 10px"
              @change="selectCompany"
            > -->
            <el-select
              v-model="co_id"
              placeholder="请选择"
              style="width: 370px; margin-left: 10px"
            >
              <el-option
                v-for="item in company_list"
                :key="item.value"
                :label="item.company_name"
                :value="item.co_id"
              ></el-option>
            </el-select>
          </div>
          <div>
            <span>快递单号</span>
            <el-input
              v-model="express_no"
              placeholder="请输入"
              style="width: 370px; margin-top: 20px; margin-left: 10px"
            ></el-input>
          </div>
        </div>
        <div style="text-align: right; padding-right: 20px">
          <el-button type="primary" @click="resetGoods" style="width: 92px"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 物流详情弹窗 -->
    <el-dialog
      title="查看物流"
      :visible.sync="express"
      width="800px"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <div>
        <div
          style="padding: 24px 30px 20px 70px; height: 600px; overflow: auto"
        >
          <div>
            <span style="color: #666">快递公司：</span>
            <span>{{ express_data.express_name }}</span>
          </div>
          <div style="margin: 10px 0">
            <span style="color: #666">运单号：</span>
            <span>{{ express_data.express_no }}</span>
          </div>
          <div style="margin-bottom: 30px">
            <span style="color: #666">联系电话：</span>
            <span>{{ express_data.express_phone }}</span>
          </div>

          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in express_data.logistics_list"
              :key="index"
              :icon="item.icon"
              :type="item.type"
              :color="item.color"
              :size="item.size"
              :timestamp="item.time"
              >{{ item.context }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <div style="text-align: right; padding-right: 20px">
          <el-button type="primary" @click="express = false" style="width: 92px"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 修改地址弹窗 -->
    <el-dialog title="修改地址" :visible.sync="addressShow" width="700px">
      <div class="bb">
        <p class="top">原收货信息</p>
        <div class="flex info">
          <div>
            <span class="label">收货人</span>
            <span class="text">{{ orderAddressInfo.consignee_name }}</span>
          </div>
          <div>
            <span class="label">联系电话</span>
            <span class="text">{{ orderAddressInfo.consignee_phone }}</span>
          </div>
        </div>
        <div class="flex info">
          <div>
            <span class="label">省市区县</span>
            <span class="text"
              >{{ orderAddressInfo.province_name
              }}{{ orderAddressInfo.city_name
              }}{{ orderAddressInfo.district_name }}</span
            >
          </div>
        </div>
        <div class="flex info">
          <div>
            <span class="label">详细地址</span>
            <span class="text">{{ orderAddressInfo.address }}</span>
          </div>
        </div>
        <div class="flex info">
          <div>
            <span class="label">配送方式</span>
            <span class="text">{{
              orderAddressInfo.delivery == 1 ? "快递" : "自提"
            }}</span>
          </div>
          <div v-if="orderAddressInfo.company_name">
            <span class="label">快递公司</span>
            <span class="text">{{ orderAddressInfo.company_name }}</span>
          </div>
          <div>
            <span class="label">运费</span>
            <span class="text" style="color: #f24e33"
              >{{ orderAddressInfo.freight_price }}元</span
            >
          </div>
        </div>
        <div class="tips">
          <p>
            1、原则上应控制收货地址的修改范围，保证运费不变；如产生运费差价，请及时沟通确定承担方。
          </p>
        </div>
        <div class="newAddress bb">
          <div style="margin-bottom: 5px">
            <span style="margin-right: 5px">配送方式</span>
            <el-radio-group
              v-model="form.delivery"
              @change="getNewFreightPrice"
              :disabled="detail.status == 2"
            >
              <el-radio :label="1">快递</el-radio>
              <el-radio :label="2">自提</el-radio>
            </el-radio-group>
          </div>
          <!-- 快递方式 -->
          <div v-if="form.delivery == 1">
            <p class="top">新收货信息</p>
            <el-form
              label-position="left"
              inline
              ref="form"
              :model="form"
              label-width="70px"
              size="small"
            >
              <div class="flex">
                <el-form-item label="收货人">
                  <el-input v-model="form.consignee_name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" style="margin-left: 15px">
                  <!-- <el-input-number v-model="form.consignee_phone"
                                                    :controls="false"
                                                    :min="1"
                                                    :max="99999999999"></el-input-number> -->
                  <el-input
                    v-model="form.consignee_phone"
                    v-number-only
                    @keyup.enter.native="handleQuery"
                    placeholder="请输入手机号码"
                    maxlength="11"
                  >
                  </el-input>
                </el-form-item>
              </div>

              <div>
                <el-form-item label="省市区">
                  <el-cascader
                    v-model="form.regions"
                    :options="regions"
                    :props="{ expandTrigger: 'hover' }"
                    @change="getNewFreightPrice"
                  ></el-cascader>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="详细地址">
                  <el-input
                    v-model="form.address"
                    style="width: 495px"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex info">
                <div>
                  <span class="label">运费</span>
                  <span class="text" style="color: #f24e33"
                    >{{ form.freight_price }}元</span
                  >
                </div>
              </div>

              <div>
                <p style="color: #707070; margin-bottom: 10px">修改备注</p>
                <el-form-item>
                  <el-input
                    v-model="form.remark"
                    type="textarea"
                    maxlength="255"
                    show-word-limit
                    :rows="3"
                    style="width: 570px"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>

          <div v-if="form.delivery == 2">
            <p class="top">自提信息</p>
            <el-form
              label-position="left"
              inline
              ref="form"
              :model="form"
              label-width="70px"
              size="small"
            >
              <div class="my_text">
                <span style="width: 70px; margin-right: 12px">提货地址</span>
                <span
                  >{{ detail.address_info.province_name
                  }}{{ detail.address_info.city_name
                  }}{{ detail.address_info.district_name
                  }}{{ detail.address_info.address }}</span
                >
              </div>
              <div class="my_text">
                <span style="width: 70px; margin-right: 12px">提货时间</span>
                <span>{{ form.time_slot[0] }}-{{ form.time_slot[1] }}</span>
              </div>
              <div>
                <p style="color: #707070; margin-bottom: 10px">修改备注</p>
                <el-form-item>
                  <el-input
                    v-model="form.remark"
                    type="textarea"
                    :rows="3"
                    style="width: 570px"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addressShow = false">取 消</el-button>
        <el-button type="primary" @click="saveOrderAddressInfo">保存</el-button>
      </span>
    </el-dialog>
    <div class="page_title flex_between">
      <span>
        <span>订单详情</span>
        <span class="remark">
          <span
            >备注（
            <span v-if="detail.shop_remark">
              {{ detail.shop_remark }}
            </span>
            <span
              class="spanBtn"
              v-if="!detail.shop_remark && module_id_array.includes('124')"
              @click="
                hospitalremark = true;
                shop_remark = '';
              "
              :disabled="
                module_id_array[0] ? !module_id_array.includes('124') : false
              "
              >填写</span
            >
            <span
              class="spanBtn"
              v-if="detail.shop_remark && module_id_array.includes('124')"
              @click="
                shop_remark = detail.shop_remark;
                hospitalremark = true;
              "
              :disabled="
                module_id_array[0] ? !module_id_array.includes('124') : false
              "
              >修改</span
            >
            ）
          </span>
        </span>
      </span>

      <el-button type="primary" style="width: 92px" size="medium" @click="back"
        >返回
      </el-button>
    </div>

    <div class="content">
      <el-steps
        :active="active"
        process-status="wait"
        align-center
        v-if="detail.status == 10"
      >
        <el-step title="提交订单" :description="detail.create_time"></el-step>
        <el-step title="支付订单" :description="detail.pay_time"></el-step>
        <el-step
          title="拼团失败，取消订单"
          :description="detail.group_completion_time"
        ></el-step>
      </el-steps>
      <el-steps
        :active="active"
        process-status="wait"
        align-center
        v-if="
          detail.status != 5 &&
            detail.status != 6 &&
            detail.status != 7 &&
            detail.status != 10
        "
      >
        <el-step title="提交订单" :description="detail.create_time"></el-step>
        <el-step title="支付订单" :description="detail.pay_time"></el-step>
        <el-step title="支付拼团" v-if="detail.is_group_order != 0"></el-step>
        <el-step title="商家发货" :description="detail.send_time"></el-step>
        <el-step title="客户收货" :description="detail.dsh_countdown"></el-step>
        <el-step
          title="订单完成"
          :description="detail.completion_time"
        ></el-step>
      </el-steps>
      <div class="statusbox flex align-center">
        <div class="flex align-center">
          当前订单状态：
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 1"
            >待付款</span
          >
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 2 && detail.is_group_order != 1"
            >待发货</span
          >
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 2 && detail.is_group_order == 1"
            >待成团</span
          >
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 10"
            >拼团失败，取消订单</span
          >
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 3"
            >待收货</span
          >
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 4"
            >已完成</span
          >
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 5"
            >已取消[超时未支付]</span
          >
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 6"
            >已取消[用户取消]</span
          >
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 8 && detail.refund_status == 1"
            >已取消[申请退款]</span
          >
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 7"
            >已取消[超时未发货]</span
          >
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 9"
            >已取消[商户取消]</span
          >
          <span
            style="margin-left: 15px"
            v-if="
              detail.status == 5 || detail.status == 6 || detail.status == 7
            "
          >
            售后状态：{{ detail.refund_status == 0 ? "未退款" : "已退款" }}
            <template v-if="detail.refund_time"
              >退款时间：{{ detail.refund_time }}</template
            >
          </span>
          <span
            style="color: #1890ff; font-weight: 600"
            v-if="detail.status == 8 && detail.refund_status == 0"
            >退款待审核</span
          >
          <el-button
            type="danger"
            style="margin-left: 10px"
            v-if="
              detail.status == 8 &&
                detail.refund_status == 0 &&
                (module_id_array.includes('86') ||
                  module_id_array.includes('999'))
            "
            @click="confirmRefundUserOrder"
            >确定退款
          </el-button>
          <el-button
            type="primary"
            style="margin-left: 10px"
            v-if="
              detail.status == 8 &&
                detail.refund_status == 0 &&
                (module_id_array.includes('86') ||
                  module_id_array.includes('999'))
            "
            @click="refuseRefundUserOrder"
            >拒绝退款
          </el-button>
        </div>

        <div>
          <el-button
            v-if="
              detail.status == 1 &&
                (module_id_array.includes('176') ||
                  module_id_array.includes('999'))
            "
            type="primary"
            style="width: 120px"
            @click="cancelOrder"
            >取消订单</el-button
          >
          <el-button
            type="primary"
            style="width: 120px"
            v-if="
              (detail.status == 2 ||
                detail.status == 3 ||
                detail.status == 4) &&
                (module_id_array.includes('174') ||
                  module_id_array.includes('999')) &&
                detail.is_group_order != 1
            "
            @click="orderDialog"
            >申请售后
          </el-button>
          <el-button
            type="primary"
            style="width: 120px"
            v-if="
              (detail.status == 2 || detail.status == 3) &&
                detail.ifAllSend &&
                (module_id_array.includes('78') ||
                  module_id_array.includes('999')) &&
                detail.is_group_order != 1
            "
            @click="sendGoodsDialog"
            >发 货
          </el-button>
          <el-button
            v-if="detail.card_num"
            type="primary"
            style="width: 120px"
            @click="checkCardInfo"
            >查看礼品卡</el-button
          >
          <el-button
            v-if="
              detail.is_group_order != 0 &&
                (module_id_array.includes('190') ||
                  module_id_array.includes('999'))
            "
            type="primary"
            style="width: 120px"
            @click="checkGroupDetails"
            >查看拼团详情</el-button
          >
        </div>
      </div>
    </div>
    <div
      style=" 
        height: 2px;
        background: #ececec;
        margin: 0 24px;
        box-sizing: border-box;
      "
    ></div>
    <div class="content">
      <div>
        <div class="title">基本信息</div>
        <div class="infobox">
          <div style="display: flex">
            <div style="width: 260px">
              <span class="little_title">订单编号</span>
              <span>{{ detail.order_sn }}</span>
            </div>
            <div style="width: 260px">
              <span class="little_title">提交时间</span>
              <span>{{ detail.create_time }}</span>
            </div>
          </div>
          <div style="display: flex; margin: 30px 0 20px">
            <div style="width: 260px">
              <span class="little_title">用户姓名</span>
              <span>{{ detail.real_name }}</span>
            </div>
            <div style="width: 260px">
              <span class="little_title">用户手机号</span>
              <span>{{ detail.phone }}</span>
            </div>
          </div>
          <div style="display: flex; margin: 30px 0 20px">
            <div style="display: flex">
              <span class="little_title" style="white-space: nowrap"
                >订单备注</span
              >
              <span>{{ detail.user_remark }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        style=" 
          width: 100%;
          height: 2px;
          background: #ececec;
          margin-bottom: 12px;
        "
      ></div>
      <div>
        <div class="title">
          {{ detail.delivery == 1 ? "快递信息" : "自提信息" }}
          <span
            style="color: rgb(24, 144, 255); font-size: 14px; cursor: pointer"
            @click="showAddress"
            v-show="detail.status == 1 || detail.status == 2"
            v-if="
              module_id_array.includes('76') || module_id_array.includes('999')
            "
            >修改地址</span
          >
        </div>
        <div class="infobox">
          <div style="display: flex; margin-bottom: 30px">
            <div style="width: 260px" v-if="detail.address_info.consignee_name">
              <span class="little_title">收货人</span>
              <span>{{ detail.address_info.consignee_name }}</span>
            </div>
            <div
              style="width: 260px"
              v-if="detail.address_info.consignee_phone"
            >
              <span class="little_title">联系电话</span>
              <span>{{ detail.address_info.consignee_phone }}</span>
            </div>
            <div style="width: 260px">
              <span class="little_title" v-if="detail.delivery == 2"
                >提货时间</span
              >
              <span style="color: #666">{{
                detail.address_info.time_slot
              }}</span>
            </div>
            <div>
              <span class="little_title">{{
                detail.delivery == 1 ? "收货地址" : "自提地址"
              }}</span>
              <span style="color: #666"
                >{{ detail.address_info.province_name
                }}{{ detail.address_info.city_name
                }}{{ detail.address_info.district_name
                }}{{ detail.address_info.address }}</span
              >
            </div>
          </div>

          <div style="margin-bottom: 30px" class="flex">
            <div style="width: 260px">
              <span class="little_title">配送方式</span>
              <span>{{ detail.delivery == 1 ? "快递" : "自提" }}</span>
            </div>
            <!-- <div class="flex wrap" style="width: 800px">
              <div
                class="flex"
                style="width: 100%; padding-bottom: 10px"
                v-for="(item, index) in detail.order_express_list"
                :key="index"
              >
                <div style="width: 260px" v-if="item.user_express_name">
                  <span class="little_title">快递公司</span>
                  <span>{{ item.user_express_name }}</span>
                </div>
                <div style="width: 260px" v-if="item.express_name">
                  <span class="little_title">快递公司（实际发货）</span>
                  <span>{{ item.express_name }}</span>
                </div>
                <div
                  style="width: 400px"
                  v-if="item.express_no"
                  class="flex align-center"
                >
                  <span class="little_title">快递单号</span>
                  <span>{{ item.express_no }}</span>
                  <el-button
                    type="text"
                    style="margin-left: 5px; padding: 0"
                    v-if="
                      module_id_array.includes('88') ||
                        module_id_array.includes('999')
                    "
                    @click="updateOrderExpress(item.express_id)"
                    >更新单号
                  </el-button>
                  <el-button
                    v-if="
                      (detail.status == 3 || detail.status == 4) &&
                        detail.delivery == 1
                    "
                    type="text"
                    @click="orderExpress(item.express_no)"
                    >查看物流
                  </el-button>
                  <el-button
                    type="text"
                    style="margin-left: 5px; padding: 0"
                    v-if="
                      (module_id_array.includes('125') ||
                        module_id_array.includes('999')) &&
                        index > 0
                    "
                    @click="delOrderExpress(item.express_id)"
                    >删除单号
                  </el-button>
                  <el-button
                    type="text"
                    style="margin-left: 5px; padding: 0"
                    v-if="
                      (module_id_array.includes('78') ||
                        module_id_array.includes('999')) &&
                        index == detail.order_express_list.length - 1
                    "
                    @click="addOrderExpress"
                    >新增单号
                  </el-button>
                </div>
              </div>
            </div> -->
          </div>
          <div style="display: flex; margin-bottom: 30px">
            <div v-if="detail.modify_receiving_goods_remark">
              <span class="little_title">修改备注</span>
              <span>{{ detail.modify_receiving_goods_remark }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        style=" 
          width: 100%;
          height: 2px;
          background: #ececec;
          margin-bottom: 12px;
        "
      ></div>
      <div class="title">商品信息</div>
      <el-table
        ref="multipleTable"
        :data="detail.item_list"
        tooltip-effect="dark"
        style="width: 100%; min-width: 1000px"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F5F5F5' }"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="商品" width="260">
          <template slot-scope="scope">
            <div class="flex align-center">
              <img
                :src="scope.row.picture"
                alt
                style="width: 76px; height: 76px; margin-right: 10px"
              />
              <div>
                <p>{{ scope.row.goods_name }}</p>
                <p style="color: #3f86ff" v-if="scope.row.service_show == 2">
                  售后完成
                </p>
                <p style="color: red" v-if="scope.row.service_show == 1">
                  售后中
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column
          label="规格"
          prop="sku_name"
          width="60"
        ></el-table-column>
        <el-table-column label="商品编码" prop="goods_code"></el-table-column>
        <el-table-column label="价格" prop="price" width="60"></el-table-column>
        <el-table-column
          label="购买数量"
          prop="num"
          width="70"
        ></el-table-column>
        <el-table-column
          label="快递渠道"
          prop="express_name"
          width="70"
        ></el-table-column>
        <el-table-column label="快递单号" prop="express_no">
          <template slot-scope="scope">
            <div v-if="scope.row.express_no">
              <span
                style="cursor: pointer;color: #3f86ff;"
                @click="orderExpress(scope.row.express_no)"
                >{{ scope.row.express_no }}</span
              >
              <span @click="updateOrderExpress(scope.row)"
                ><i
                  class="el-icon-edit"
                  style="color: #3f86ff;cursor: pointer;"
                ></i
              ></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-for="(item, index) in detail.price_list"
        :key="index"
        :style="item.name == '合计' ? 'font-size:18px;' : ''"
        style="text-align: right; line-height: 30px"
      >
        <span>{{ item.name }}</span
        ><span
          class="text-main"
          :style="item.name == '合计' ? 'color:red;' : 'color:#666;'"
          style="margin-left: 10px"
          >￥{{ item.price }}</span
        >
      </div>
    </div>
    <!-- 修改备注 -->
    <el-dialog
      title="编辑备注信息"
      :visible.sync="hospitalremark"
      width="400px"
      :close-on-click-modal="false"
    >
      <div style="text-align: left">
        <div>备注</div>
        <el-input
          v-model="platform_hospital_remark"
          maxlength="100"
          placeholder="100个字以内"
          style="margin: 10px 0 20px"
        ></el-input>
        <div style="text-align: center">
          <el-button @click="hospitalremark = false" plain size="medium"
            >取消
          </el-button>
          <el-button @click="addRemark" type="primary" size="medium"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="查看礼品卡" :visible.sync="checkCardInfoShow">
      <div
        style=" 
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
        "
      >
        <div style="width: 50%; display: flex; align-items: center">
          <div>礼品卡名称:</div>
          <div>{{ PlatCardInfo.card_name }}</div>
        </div>
        <div style="width: 50%; display: flex; align-items: center">
          <div>礼品卡号:</div>
          <div>{{ PlatCardInfo.card_num }}</div>
        </div>
        <div style="width: 50%; display: flex; align-items: center">
          <div>购买手机:</div>
          <div>{{ PlatCardInfo.grant_phone }}</div>
        </div>
        <div style="width: 50%; display: flex; align-items: center">
          <div>购买时间:</div>
          <div>
            {{ dayjs.unix(PlatCardInfo.grant_time).format("YYYY-MM-DD HH:mm") }}
          </div>
        </div>
        <div style="width: 50%; display: flex; align-items: center">
          <div>购买订单:</div>
          <div>{{ PlatCardInfo.grant_order_sn }}</div>
        </div>
        <div style="width: 50%; display: flex; align-items: center">
          <div>绑定时间:</div>
          <div>
            {{ dayjs.unix(PlatCardInfo.bind_time).format("YYYY-MM-DD HH:mm") }}
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="checkCardInfoShow = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { json } from "body-parser";
import { uploadImg } from "../../service/common";
import { Loading } from "element-ui";
export default {
  name: "orderDeatil",
  filters: {
    filterStatus(val) {
      if (val == 1) {
        return "仅退款";
      }
      if (val == 2) {
        return "退货退款";
      }
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      console.log(
        "val",
        value,
        this.ruleForm.order_yue,
        Number(this.ruleForm.order_yue) < Number(value)
      );
      if (!value || value == "undefined" || value == "") {
        callback(new Error("请输入退款金额"));
      } else {
        if (Number(this.ruleForm.order_yue) < Number(value)) {
          callback(new Error("填写的退款金额不能超过订单余额"));
        }
        callback();
      }
    };
    return {
      order_id: this.$route.query.order_id,
      dialog: false,
      beforeDialog: false,
      add_dialog: false,
      reset_dialog: false,
      express: false,
      detail: {
        status: 1,
        address_info: {},
        item_list: [],
      },
      company_list: [],
      express_data: {},
      platform_hospital_remark: "",
      hospitalremark: false,
      add_co_id: "",
      add_express_no: "",
      co_id: "",
      express_no: "",
      addressShow: false,
      express_ids: "",
      form: {
        delivery: "",
        consignee_name: "",
        consignee_phone: "",
        address: "",
        remark: "",
        regions: [],
        freight_price: "0",
        time_slot: ["", ""],
      }, //新地址信息
      regions: [], //地区
      orderAddressInfo: {}, //原收货信息
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      checkCardInfoShow: false,
      PlatCardInfo: {},
      multipleSelection: [],
      afterBuy: false,
      afterList: [], //售后商品列表
      ruleForm: {
        refund_image: [],
      },
      address_info: {
        regions: [],
        city_name: "",
        district_name: "",
        province_name: "",
        address: "",
        city_id: "",
        district_id: "",
        name: "",
        phone: "",
        province_id: "",
      },
      goodsTypeSet: {
        value: "value",
        label: "label",
        children: "children",
      },
      rules: {
        service_type: [
          { required: true, message: "请选择退款类型", trigger: "change" },
        ],
        refund_desc: [
          { required: true, message: "请选择退款原因", trigger: "change" },
        ],
        // refund_price: [{ validator: validatePass, trigger: "blur" }],
        refund_price: [
          { required: true, message: "请输入退款金额", trigger: "blur" },
        ],
      },
      dialogImageUrl: "",
      fileList: [],
      dialogVisible: false,
      afterReason: [], //售后原因
      verificationShow: false,
      certenAfterShow: false, //确认退款
      btnText: "发送验证码",
      canClick: false,
      totalTime: 60,
      code: "",
      afterBuyCertendata: {},
      choose_item_list: [], //选中的发货id
      choose_item_id: "", //改物流的选中id
      service_id: "",
      certenAfterAdress: false, //退货退款修改地址的确认框
    };
  },
  computed: {
    active() {
      switch (this.detail.status) {
        case 1:
          return 2;
        case 2:
          if (this.detail.is_group_order == 0) {
            return 3;
          }
          if (
            this.detail.is_group_order == 2 ||
            this.detail.is_group_order == 1
          ) {
            return 4;
          }
        case 3:
          if (this.detail.is_group_order == 0) {
            return 4;
          }
          if (
            this.detail.is_group_order == 2 ||
            this.detail.is_group_order == 1
          ) {
            return 5;
          }
        case 4:
          if (this.detail.is_group_order == 0) {
            return 5;
          }
          if (this.detail.is_group_order == 2) {
            return 6;
          }
          if (this.detail.is_group_order == 1) {
            return 6;
          }
        case 10:
          return 3;
      }
    },
  },
  created() {
    this.getRegion();
    this.getDetail();
    this.AfterReason();
  },
  updated() {},
  methods: {
    //返回
    back() {
      history.back();
    },
    //添加备注
    addRemark() {
      if (!this.platform_hospital_remark) {
        this.$message.error("请填写备注");
        return false;
      }
      let data = {
        order_id: this.$route.query.order_id,
        shop_remark: this.platform_hospital_remark,
      };
      this.axios.post("/store/shop/editOrderRemarkInfo", data).then((res) => {
        if (res.code == 0) {
          this.$message.success("保存成功");
          this.hospitalremark = false;
          this.getDetail();
        }
      });
    },
    //获取详情
    getDetail() {
      this.axios
        .post("/store/Shoporder/getOrderInfo", { order_id: this.order_id })
        .then((res) => {
          if (res.code == 0) {
            let info = res.data.info;

            if (info.create_time) {
              info.create_time = this.dayjs(info.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            if (info.pay_time) {
              info.pay_time = this.dayjs(info.pay_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            if (info.send_time) {
              info.send_time = this.dayjs(info.send_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            if (info.completion_time) {
              info.completion_time = this.dayjs(
                info.completion_time * 1000
              ).format("YYYY-MM-DD HH:mm:ss");
            }
            if (info.refund_time) {
              info.refund_time = this.dayjs(info.refund_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }

            info.item_list.forEach((ele) => {
              ele.create_time = this.dayjs(ele.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            });
            info.ifAllSend = info.item_list
              .map((res) => {
                return res.delivery_status;
              })
              .includes(0);
            info.group_completion_time = info.group_completion_time
              ? this.dayjs(info.group_completion_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "";
            this.detail = info;
            const arrCan = this.detail.item_list.filter((el) => {
              return el.delivery_status != 1;
            });
            console.log("arrCan", arrCan);
            if (arrCan.length == 1) {
              this.choose_item_list = [arrCan[0].item_id];
            }
            this.address_info = info.service_address;
            this.address_info.regions = [
              info.service_address.province_id,
              info.service_address.city_id,
              info.service_address.district_id,
            ];
          }
        });
    },

    //发货弹窗
    sendDialog() {
      console.log("choose_item_list", this.choose_item_list);
      console.log("this.detail.item_list", this.detail.item_list);
      // 判断是否包含售后商品
      var filtered_items = this.detail.item_list.filter((item) => {
        return (
          this.choose_item_list.includes(item.item_id) &&
          (item.service_show == 1 || item.service_show == 2)
        );
      });
      // 如果有售后商品就去弹框确认是否发货
      if (filtered_items.length) {
        this.beforeDialog = true;
      } else {
        // 直接去走确认后的流程
        this.sendGoods();
      }
    },
    // 确认发货弹框后的流程
    sendGoodsDialog() {
      if (this.detail.delivery == 1) {
        this.axios
          .post("/store/Shoporder/getExpressCompanyList")
          .then((res) => {
            if (res.code == 0) {
              this.company_list = res.data.list;
              // let obj =
              //   this.company_list.find((ele) => {
              //     return ele.company_name == this.detail.express_name;
              //   }) || {};
              // this.co_id = obj.co_id;
            }
          });
        this.express_no = "";
        this.co_id = "";
        this.dialog = true;
      } else {
        this.sendGoods();
      }
      this.beforeDialog = false;
      this.getDetail();
    },
    // 查看礼品卡
    checkCardInfo() {
      this.checkCardInfoShow = true;
      const data = {
        card_num: this.detail.card_num,
      };
      this.axios.post("/store/shoporder/getPlatCardInfo", data).then((res) => {
        this.PlatCardInfo = res.data;
      });
    },
    // 查看拼团详情
    checkGroupDetails() {
      this.$router.push({
        path: "/order/GroupDetails",
        query: {
          order_group_id: this.detail.order_group_id,
        },
      });
    },
    // 新增物流弹窗
    addOrderExpress() {
      this.axios.post("/store/Shoporder/getExpressCompanyList").then((res) => {
        if (res.code == 0) {
          this.company_list = res.data.list;
          let obj =
            this.company_list.find((ele) => {
              return ele.company_name == this.detail.express_name;
            }) || {};
          this.co_id = obj.co_id;
          this.add_dialog = true;
        }
      });
    },
    delOrderExpress(express_id) {
      this.$confirm("此操作将删除物流号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            order_id: this.order_id,
            express_id: express_id,
          };
          this.axios
            .post("/store/shoporder/delOrderExpress", data)
            .then((res) => {
              this.$message.success("删除成功");
              this.getDetail();
            });
        })
        .catch(() => {});
    },
    //打开重置物流弹窗
    // updateOrderExpress(express_id) {
    //   this.express_ids = express_id;
    //   this.axios.post("/store/Shoporder/getExpressCompanyList").then((res) => {
    //     if (res.code == 0) {
    //       this.company_list = res.data.list;
    //       let obj =
    //         this.company_list.find((ele) => {
    //           return ele.company_name == this.detail.express_name;
    //         }) || {};
    //       this.co_id = obj.co_id;
    //       this.reset_dialog = true;
    //     }
    //   });
    // },
    updateOrderExpress(val) {
      this.express_no = val.express_no;
      this.choose_item_id = val.item_id;
      this.axios.post("/store/Shoporder/getExpressCompanyList").then((res) => {
        if (res.code == 0) {
          this.company_list = res.data.list;
          let obj =
            this.company_list.find((ele) => {
              return ele.company_name == val.express_name;
            }) || {};
          this.co_id = obj.co_id;
          this.reset_dialog = true;
        }
      });
    },
    //修改快递公司
    selectCompany(value) {
      if (this.detail.user_express_name) {
        let obj = this.company_list.find((ele) => {
          return ele.co_id == value;
        });
        if (obj.company_name != this.detail.user_express_name) {
          this.$confirm("患者已选择了快递公司，确认要修改？ ", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          }).catch(() => {
            let obj = this.company_list.find((ele) => {
              return ele.company_name == this.detail.user_express_name;
            });
            this.co_id = obj.co_id;
          });
        }
      }
    },
    //打开修改信息
    showAddress() {
      this.getOrderAddressInfo();
      this.addressShow = true;
    },
    //获取原收货信息
    getOrderAddressInfo() {
      let data = {
        order_id: this.$route.query.order_id,
      };
      this.axios
        .post("/store/Shoporder/getOrderAddressInfo", data)
        .then((res) => {
          let info = res.data.info;
          let regions = [];
          this.orderAddressInfo = info;
          if (info.province_id) {
            regions.push(info.province_id);
            regions.push(info.city_id);
            if (info.district_id) {
              regions.push(info.district_id);
            }
          }
          this.form.regions = regions;
          this.form.address = info.address;
          this.form.consignee_name = info.consignee_name;
          this.form.consignee_phone = info.consignee_phone;
          this.form.freight_price = info.freight_price;
          this.form.delivery = info.delivery;
          this.form.remark = info.modify_receiving_goods_remark;
          this.form.time_slot = info.time_slot;
          this.$forceUpdate();
        });
    },

    //保存新修改地址
    saveOrderAddressInfo() {
      let form = this.form;
      if (
        form.delivery == 1 &&
        !(
          form.consignee_name &&
          form.consignee_phone &&
          form.remark &&
          form.regions.length > 0 &&
          form.address
        )
      ) {
        this.$message.error("请填写完新地址信息");
        return;
      }
      // if (!(form.consignee_name && form.consignee_phone  && form.remark &&
      //     ((form.delivery == 1 && form.regions.length > 0 && form.address) ||
      //     form.delivery == 2)
      //     )
      // ) {
      //     this.$message.error('请填写完新地址信息');
      //     return;
      // }
      let data = {
        order_id: this.order_id,
        consignee_name: form.consignee_name,
        consignee_phone: form.consignee_phone,
        address: form.address,
        remark: form.remark,
        delivery: form.delivery,
        province_id: form.regions[0],
        city_id: form.regions[1],
        district_id: form.regions[2],
      };
      this.axios
        .post("/store/Shoporder/saveOrderAddressInfo", data)
        .then((res) => {
          this.$message.success("修改成功");
          this.form = {
            consignee_name: "",
            consignee_phone: "",
            address: "",
            remark: "",
            regions: [],
            freight_price: "",
            delivery: 1,
          };
          this.addressShow = false;
          this.getDetail();
        });
    },

    //获取新运费
    getNewFreightPrice() {
      if (this.form.delivery == 2) {
        this.form.freight_price = 0.0;
        return;
      }
      if (this.form.regions.length == 0) return;
      let data = {
        city_id: this.form.regions[1],
        order_id: this.order_id,
      };
      this.axios
        .post("/store/Shoporder/getNewFreightPrice", data)
        .then((res) => {
          this.form.freight_price = res.data.freight_price;
        })
        .catch((err) => {
          this.form.regions = [];
        });
    },
    //发货
    sendGoods() {
      if (this.detail.delivery == 1) {
        if (!this.co_id || !this.express_no) {
          this.$message.error("请填写快递信息");
          return;
        }
        if (!this.choose_item_list.length) {
          this.$message.error("请选择商品");
          return;
        }
        let data = {
          order_id: this.$route.query.order_id,
          co_id: this.co_id,
          express_no: this.express_no,
          item_list: this.choose_item_list,
        };
        console.log("011", this.choose_item_list);
        this.axios
          .post("/store/Shoporder/submitOrderExpress", data)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("发货成功");
              this.choose_item_list = [];
              this.getDetail();
              // history.back();
            }
          });
      } else {
        let data = {
          order_id: this.$route.query.order_id,
          item_list: this.detail.item_list.map((res) => {
            return res.item_id;
          }),
        };
        console.log("data", data);
        this.$confirm(
          "自提订单发货请提醒客户点击确认收货,以防造成交易纷争及订单丢失 ",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.axios
              .post("/store/Shoporder/submitOrderExpress", data)
              .then((res) => {
                if (res.code == 0) {
                  this.getDetail();
                  this.$message.success("发货成功");
                  // history.back();
                }
              });
          })
          .catch(() => {});
      }
      this.dialog = false;
      this.beforeDialog = false;
    },
    //重置物流
    resetGoods() {
      let data = {
        order_id: this.$route.query.order_id,
        co_id: this.co_id,
        // express_id: this.express_ids,
        express_no: this.express_no,
        item_id: this.choose_item_id,
      };
      this.axios
        .post("/store/Shoporder/updateOrderExpress", data)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getDetail();
            this.reset_dialog = false;
          }
        });
    },
    //新增物流
    addGoods() {
      let data = {
        order_id: this.$route.query.order_id,
        co_id: this.add_co_id,
        express_no: this.add_express_no,
      };
      this.axios
        .post("/store/Shoporder/submitOrderExpress", data)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("新增成功");
            this.getDetail();
            this.add_co_id = "";
            this.add_express_no = "";
            this.add_dialog = false;
          }
        });
    },
    //查看物流信息
    orderExpress(express_no) {
      this.axios
        .post("/store/Shoporder/getOrderLogisticsInfo", {
          order_id: this.order_id,
          express_no: express_no,
        })
        .then((res) => {
          this.express_data = res.data.info;
          this.express_data.logistics_list[0].type = "primary";
          this.express_data.logistics_list[0].size = "large";
          setTimeout(() => {
            this.express = true;
          }, 500);
        })
        .catch((err) => {});
    },
    transRegions(tree) {
      tree.forEach((item) => {
        if (item.city || item.district) {
          item.children = item.city || item.district;
          delete item.city;
          delete item.district;
          this.transRegions(item.children);
        }
        if (item.province_id) {
          item.value = item.province_id;
          delete item.province_id;
        }
        if (item.city_id) {
          item.value = item.city_id;
          delete item.city_id;
        }
        if (item.district_id) {
          item.value = item.district_id;
          delete item.district_id;
        }

        if (item.name) {
          item.label = item.name;
          delete item.name;
        }
      });
    },
    //获取省市区列表
    getRegion() {
      let url = "/store/platform/getProvinceCityDistrict";
      this.axios.post(url).then((res) => {
        let regions = res.data.list;
        this.transRegions(regions);
        this.regions = regions;
      });
    },
    // 确认退款
    confirmRefundUserOrder() {
      this.$confirm("确认退款后，支付金额将原路退回用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            order_id: this.order_id,
          };
          return this.axios.post(
            "/store/Shoporder/confirmRefundUserOrder",
            data
          );
        })
        .then(() => {
          this.getDetail();
          this.$message.success("退款成功");
        })
        .catch(() => {});
    },
    //取消退款
    refuseRefundUserOrder() {
      this.$confirm("拒绝退款后，订单状态将返回待发货, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            order_id: this.order_id,
          };
          return this.axios.post(
            "/store/Shoporder/refuseRefundUserOrder",
            data
          );
        })
        .then(() => {
          this.getDetail();
          this.$message.success("取消成功");
        })
        .catch(() => {});
    },
    // 选中售后商品
    handleSelectionChange(val) {
      // this.multipleSelection = [];
      console.log("val", val);
      if (val.length > 1) {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(val[val.length - 1]);
      }
      this.multipleSelection = val.length ? [val[val.length - 1]] : [];
      console.log("0123", this.multipleSelection);
    },
    // 是否申请售后
    orderDialog() {
      this.fileList = [];
      const val = this.multipleSelection;
      console.log("val", val);
      if (!val.length) {
        this.$message.error("请勾选商品");
        return;
      }
      // 如果改订单正在申请售后则无法发起申请售后
      if (val[0].service_show == 1) {
        this.$message.error("该商品正在售后中！");
        return;
      }
      //校验订单是否有进行中的售后单
      // const ifInclude1 = this.detail.item_list
      //   .map((res) => {
      //     return res.service_show;
      //   })
      //   .includes(1);
      // if (ifInclude1) {
      //   this.$confirm("订单正在售后中，请先处理哦", "提示", {
      //     confirmButtonText: "确定",
      //     showCancelButton: false,
      //   }).then(() => {});
      //   return;
      // }
      //校验订单是否有售后单 商品{商品名称1、商品名称2...}已申请过售后不可再次申请哦
      const ifInclude2 = val
        .map((res) => {
          return res.service_show;
        })
        .includes(2);
      const name = val.filter((res) => {
        return res.service_show == 2;
      });
      if (ifInclude2) {
        const namearr = name
          .map((res) => {
            return res.goods_name;
          })
          .join(",");
        const text = `商品{${namearr}}已申请过售后不可再次申请哦`;
        this.$confirm(text, "提示", {
          confirmButtonText: "确定",
          showCancelButton: false,
        }).then(() => {});
        return;
      }
      const item = this.multipleSelection.map((res) => {
        return {
          item_id: res.item_id,
          num: 1,
        };
      });
      let data = {
        order_id: this.order_id,
        item: item,
      };
      this.axios
        .post("/store/shopafterorder/afterordergoodsinfo", data)
        .then((res) => {
          this.afterList = res.data.service_item;
          this.ruleForm = res.data;
        });
      this.afterBuy = true;
    },
    // 编辑快递单号
    editInfo(val) {},
    handleChange(value) {
      const item = this.afterList.map((res) => {
        return {
          item_id: res.item_id,
          num: res.num,
        };
      });
      let data = {
        order_id: this.order_id,
        item: item,
      };
      console.log("data", data);
      this.axios
        .post("/store/shopafterorder/afterordergoodsinfo", data)
        .then((res) => {
          this.afterList = res.data.service_item;
          this.ruleForm.tatoleprice = res.data.tatoleprice;
          this.ruleForm.order_yue = res.data.order_yue;
        });
    },
    handleRemove(file, fileList) {
      file.uid;
      const item = file.uid;
      const arr = this.ruleForm.refund_image.filter((res) => {
        return res.uid != item;
      });
      this.ruleForm.refund_image = arr;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //上传图片
    uploadImg(params, item) {
      let file = params.file;
      const loading = this.$loading({
        lock: true,
        text: "图片正在上传中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      uploadImg(file)
        .then((res) => {
          const arr = this.ruleForm.refund_image || [];
          const now = {
            uid: file.uid,
            pic: res.data.pic,
          };
          arr.push(now);
          this.ruleForm.refund_image = arr;
          console.log("this.ruleForm.refund_image", this.ruleForm.refund_image);
          this.$forceUpdate();
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loading.close();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearData(formName) {
      this.$refs[formName].resetFields();
    },
    // verification(val) {
    //   this.afterBuy = false;
    //   this.verificationShow = true;
    //   this.verification_data = val;
    // },

    /** 获取验证码 */
    sendCode() {
      if (this.canClick) return;
      const data = {
        service_id: this.service_id,
      };
      console.log("0123", data);
      this.axios
        .post("/store/shopafterorder/getAfterOrderCode", data)
        .then((res) => {
          this.canClick = true;
          this.btnText = this.totalTime + "s后重新发送";
          let clock = window.setInterval(() => {
            this.totalTime--;
            this.btnText = this.totalTime + "s后重新发送";
            if (this.totalTime < 0) {
              window.clearInterval(clock);
              this.btnText = "重新发送验证码";
              this.totalTime = 60;
              this.canClick = false;
            }
          }, 1000);
        });
    },
    // 确认售后
    certenBtn() {
      const data = {
        code: this.code,
        service_id: this.service_id,
        type: 1,
      };
      this.axios
        .post("/store/shopafterorder/agreeafterorder", data)
        .then((res) => {
          console.log(res);
          this.$message.success("申请售后成功！");
          this.verificationShow = false;
          this.getDetail();
        });
      // const imgarr = this.afterBuyCertendata.refund_image
      //   ? this.afterBuyCertendata.refund_image.map((res) => {
      //       return res.pic;
      //     })
      //   : [];
      // const data = {
      //   code: this.code,
      //   order_id: this.order_id,
      //   service_type: this.afterBuyCertendata.service_type,
      //   refund_desc: this.afterReason.filter((res) => {
      //     return res.id == this.afterBuyCertendata.refund_desc;
      //   })[0].msg,
      //   refund_remark: this.afterBuyCertendata.refund_remark,
      //   refund_image: imgarr,
      //   refund_price: this.afterBuyCertendata.refund_price,
      //   item: JSON.stringify(this.afterBuyCertendata.item),
      // };
      // console.log("data", data);
      // this.axios
      //   .post("/store/shopafterorder/subAfterSale", data)
      //   .then((res) => {
      //     console.log(res);
      //     this.$message.success("申请售后成功！");
      //     this.verificationShow = false;
      //   });
    },
    // 确定数据
    afterBuyCerten(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          const item = this.afterList.map((res) => {
            return {
              item_id: res.item_id,
              num: res.num,
              refund_freight_price: this.ruleForm.refund_freight_price,
            };
          });

          let data = {
            order_id: this.order_id,
            service_type: this.ruleForm.service_type,
            // refund_desc: this.afterReason.filter((res) => {
            //   return res.id == this.ruleForm.refund_desc;
            // })[0].msg,
            tatoleprice: this.ruleForm.tatoleprice,
            order_yue: this.ruleForm.order_yue,
            refund_desc: this.ruleForm.refund_desc,
            refund_remark: this.ruleForm.refund_remark,
            refund_image: this.ruleForm.refund_image,
            refund_price: this.ruleForm.refund_price,
            // item: JSON.stringify(item),
            item: item,
            can_refund_freight_price: this.ruleForm.can_refund_freight_price,
            refund_freight_price: this.ruleForm.refund_freight_price,
          };
          console.log("data", data);

          this.afterBuyCertendata = data;
          // 如果是退货退款则增加一个修改地址弹窗
          if (this.ruleForm.service_type == 2) {
            this.certenAfterAdress = true;
          } else {
            this.certenAfterShow = true;
          }
          this.afterBuy = false;
        } else {
          return false;
        }
      });
    },
    // 修改售后信息
    changeInfo() {
      this.certenAfterShow = false;
      this.certenAfterAdress = false;
      // const msg = this.afterBuyCertendata.refund_desc;
      // const refund_desc_id = this.afterReason.filter((res) => {
      //   return res.msg == msg;
      // })[0].id;
      this.ruleForm = this.afterBuyCertendata;
      // this.ruleForm.refund_desc = refund_desc_id;
      this.afterBuy = true;
    },
    // 确定
    certenData() {
      // this.certenAfterShow = false;
      // this.verificationShow = true;
      console.log("this.afterBuyCertendata", this.afterBuyCertendata);
      const imgarr = this.afterBuyCertendata.refund_image
        ? this.afterBuyCertendata.refund_image.map((res) => {
            return res.pic;
          })
        : [];

      const address_info_now = {
        shop_id: this.multipleSelection[0].item_id,
        province_id: this.address_info.regions[0],
        city_id: this.address_info.regions[1],
        district_id: this.address_info.regions[2],
        address: this.address_info.address,
        phone: this.address_info.phone,
        user_name: this.address_info.user_name,
      };
      const data = {
        order_id: this.order_id,
        service_type: this.afterBuyCertendata.service_type,
        refund_desc: this.afterReason.filter((res) => {
          return res.id == this.afterBuyCertendata.refund_desc;
        })[0].msg,
        refund_remark: this.afterBuyCertendata.refund_remark,
        refund_image: imgarr.join(","),
        refund_price: this.afterBuyCertendata.refund_price,
        item: JSON.stringify(this.afterBuyCertendata.item),
        address_info: address_info_now,
      };
      console.log("123123", data);
      this.axios
        .post("/store/shopafterorder/subAfterSale", data)
        .then((res) => {
          console.log(res);
          this.service_id = res.data.service_id;
          if (this.afterBuyCertendata.service_type != 1) {
            this.$message.success("申请售后成功！");
            this.certenAfterShow = false;
            this.certenAfterAdress = false;
            this.getDetail();
          } else {
            this.certenAfterShow = false;
            this.verificationShow = true;
          }
        });
    },
    // 售后原因
    AfterReason() {
      this.axios.post("/store/shopafterorder/getAfterReason").then((res) => {
        this.afterReason = res.data.list;
      });
    },
    // 取消订单
    cancelOrder() {
      // 用户名称
      const name = this.detail.real_name;
      // 订单编号
      const order = this.detail.order_sn;
      const info = `取消后订单将更新为已取消订单哦，您确定取消${name}的订单${order}？`;
      this.$confirm(info, "确认取消", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          const data = {
            order_id: this.order_id,
          };
          this.axios.post("/store/Shoporder/cancleOrder", data).then((res) => {
            console.log(res);
            this.$message.success("取消成功");
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
/deep/ .el-upload--picture-card {
  width: 75px;
  height: 75px;
}
/deep/ .el-upload {
  width: 75px;
  height: 75px;
  line-height: 75px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 75px;
  height: 75px;
  line-height: 75px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 75px;
  height: 75px;
  line-height: 75px;
}
/deep/ .avatar {
  width: 75px;
  height: 75px;
}
/deep/ .el-table .el-table__header-wrapper .el-checkbox {
  display: none;
}
</style>
<style lang="scss" scoped>
.info_main {
  .list_box {
    max-height: 210px;
    overflow-y: scroll;
    border: 1px solid #d4cece;
    padding: 15px 40px 15px 10px;

    .list_item {
      // display: flex;
      // flex-direction: column;
      .list_top {
        display: flex;
      }
      .list_bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .list_form {
      }
    }
  }
}
.text-main {
  display: inline-block;
  width: 90px;
}
.page_title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 24px;
  box-sizing: border-box;
  font-size: 18px;
  text-align: left;
  background: #fbfbfb;
  font-weight: 600;
}

.remark {
  font-size: 14px;
  margin-left: 20px;
  .spanBtn {
    font-size: 12px;
    color: #4974f5;
    cursor: pointer;
  }
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-step {
  margin-bottom: 15px;
}

.content {
  padding: 24px;
  box-sizing: border-box;
  text-align: left;
}

.steptext {
  display: flex;
  padding: 10px 30px;
  text-align: left;
}

.statusbox {
  width: 100%;
  text-align: left;
  background-color: #f1f8ff;
  border-radius: 6px;
  border: solid 1px #c3e1ff;
  padding: 10px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 24px;
}

.little_title {
  color: #000;
  font-weight: 600;
  margin-right: 10px;
}

.infobox {
  text-align: left;
}

.dialogbox {
  padding: 40px 0;
  // text-align: center;
}

.price {
  text-align: right;
  line-height: 25px;
}

.top {
  color: #262626;
  font-weight: bold;
  margin-bottom: 10px;
}

.info {
  margin-bottom: 10px;

  div + div {
    margin-left: 30px;
  }

  .label {
    color: #262626;
    margin-right: 10px;
  }

  .text {
    color: #666666;
  }
}

.tips {
  p {
    color: #fc8740;
    line-height: 25px;
  }
}

.newAddress {
  background-color: aliceblue;
  padding: 15px;
  margin-top: 10px;
}
.my_text {
  height: 32px;
  line-height: 32px;
  margin: 0 10px 18px 0;
}
</style>
<style lang="scss">
.mystep_bar .el-step__head {
  width: 170px;
}

.mystep_bar .el-step.is-center .el-step__head {
  text-align: left;
}
</style>
